<template>
  <div>
    <div>
      <v-row class="text-center">
        <v-col cols="12" md="12">
          <h1>Tous les outils nécessaires pour travailler sur vos PDF, en un seul endroit</h1>
        </v-col>
        <v-col>
          Tous les outils dont vous avez besoin pour utiliser les PDF, à portée de main. Ils sont tous 100% GRATUITS et
          simples d'utilisation ! Fusionnez, divisez, compressez, convertissez, faites pivoter, déverrouillez et ajoutez
          un filigrane à vos PDF en seulement quelques clics.
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="12" md="3" v-for="tool in tools" :key="tool.id">
          <v-card class="mx-auto" max-width="344" outlined height="200px" @click="openDialog" link>
            <v-card-title>{{ tool.title }}</v-card-title>
            <v-card-text>{{ tool.description }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-dialog v-model="dialog" max-width="600">
        <v-card>
          <v-card-title class="headline">PDF en Word</v-card-title>
          <v-card-text>
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              @vdropzone-success="onUploadSuccess"
            ></vue-dropzone>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import VueDropzone from "vue2-dropzone"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
import config from "@/views/config/config"
import { getCookie } from "@/utils/cookies"

export default {
  name: "serviceToolsPdf",
  components: {
    VueDropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${config.apiUri}/convert/pdf-to-word`,
        headers: {
          Authorization: `Bearer ${getCookie("jwt")}`,
        },
        thumbnailWidth: 150,
        maxFilesize: 2,
        acceptedFiles: "application/pdf",
      },
      dialog: false,
      tools: [
        {
          id: 1,
          title: "PDF en Word",
          description: "Fusionnez plusieurs fichiers PDF en un seul document.",
        },
        {
          id: 1,
          title: "Fusionner des PDF",
          description: "Fusionnez plusieurs fichiers PDF en un seul document.",
        },
        {
          id: 2,
          title: "Diviser des PDF",
          description: "Divisez un fichier PDF en plusieurs documents.",
        },
        {
          id: 3,
          title: "Compresser des PDF",
          description: "Réduisez la taille de vos fichiers PDF.",
        },
        {
          id: 4,
          title: "Convertir des PDF",
          description: "Convertir des fichiers PDF en d'autres formats.",
        },
        {
          id: 5,
          title: "Faire pivoter des PDF",
          description: "Faites pivoter des pages dans vos fichiers PDF.",
        },
        {
          id: 6,
          title: "Déverrouiller des PDF",
          description: "Supprimez les mots de passe de vos fichiers PDF.",
        },
        {
          id: 7,
          title: "Ajouter un filigrane à des PDF",
          description: "Ajoutez un filigrane à vos fichiers PDF.",
        },
      ],
    }
  },
  created() {
    console.log(config.apiUri)
  },
  methods: {
    onUploadSuccess(file, response) {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "converted.docx")
      document.body.appendChild(link)
      link.click()
    },
    openDialog() {
      this.dialog = true
    },
  },
}
</script>
